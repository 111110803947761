export const links = [
    {
        id: 1,
        text: 'home',
    },
    {
        id: 2,
        text: 'about',
    },
    {
        id: 3,
        text: 'skills',
    },
    {
        id: 4,
        text: 'projects',
    },
     {
        id: 5,
        text: 'education',
    },
    {
        id: 6,
        text: 'contact',
    },
    
];

