import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css'

 const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_874oecb', 'template_dx4u055', form.current, 'CpGlLdbUT2kXtEd5s')
      .then((result) => {
          console.log(result.text);
          console.log('message sent');
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section className='contact' id='contact'>
      <div className="contact-page">
        <h1>Get In Touch</h1>
        <div className="underline">
        
        </div>

        <form id="contact-form"  method="POST" ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <input type="text"  name='from_name'  className="form-control" placeholder='name' required/>
            <input type="email"  name='from_email'  className="form-control"  placeholder='email' required/>    
            <textarea className="form-control"  name='message'  rows="7" placeholder='message'></textarea>
          </div>
          <button type="submit" className="btn btn-primary btn-submit">Submit</button>
        </form>

      </div>
    </section>
  )
}

export default Contact