import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import pages
import Home from './pages/Home/Home';
// import components


function App() {
  return  (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
  
      </Routes>
    </Router>  
  )
}

export default App;
