import React, {useState} from 'react'
import './Education.css'
import { GoTriangleRight } from 'react-icons/go'
import { IoMdSchool } from 'react-icons/io'
import education from './Education-data'

const Education = () => {
    const data = education;
    const [value, setValue] = useState(0)
    const { dates, description, name, school, url} = data[value]
    
  return (
    <section className="edu" id='education'>
        <div className="title">
            <h1>Education</h1>
            <div style={{fontSize:'50px'}}><IoMdSchool/></div>
        </div>
        <div className="edu-center">
            {/* btn container */}
            <div className="btn-container">
                {data.map((item, index)=>{
                    return <button key={index} onClick={()=>setValue(index)} className={`edu-btn ${index === value && 'active-btn'}`}>{item.title}</button>
                })}
            </div>
            {/* edu info */}
            <article className="edu-info">
                <h3>
                    <a href={url} target="_blank" rel="noopener noreferrer" >{name}</a>
                </h3>
                <h4>{school}</h4>
                
                <p className="edu-date">
                    {dates}
                </p>
                {description.map((desc, index)=>{
                    return <div key={index} className='edu-desc'>
                        <GoTriangleRight className='edu-icon'></GoTriangleRight>
                        <p>{desc}</p>
                    </div>
                })}
            </article>
        </div>
    </section>
  )
}

export default Education