import React from 'react'
import './Projects.css'
import projects from './Projects-data'

const Projects = () => {

  return (
    <section className="projects" id='projects'>
      <div className="projects-container">
        <div className="projects-title">
          <h1>Projects</h1>
          <div className="underline"></div>
        </div>
        <div className="projects-content" >
           {projects.map((project, index)=>{
              const {title,urlPrev, srcImg } = project
              return (
                
                  <a key={index} href={urlPrev} target='_blank' rel="noopener noreferrer" className='project'>
                    <div className="img-container">
                        <img src={srcImg} alt={title} />
                    </div>
                    <div className="project-footer">
                      <h5>{title}</h5>
                    </div>
                  </a>              
            )
           })}
        </div>
      </div>
    </section>
  )
}

export default Projects
