import React from "react";
import { AiFillHtml5 } from 'react-icons/ai'
import { IoLogoCss3, IoLogoJavascript } from 'react-icons/io'
import { BsFillBootstrapFill } from 'react-icons/bs'
import { DiJqueryLogo } from 'react-icons/di'
import { GrReactjs } from 'react-icons/gr'

const skills = [
    {
        name: 'React',
        icon: <GrReactjs />,
        color: '#61dbfb',
    },
    {
        name: 'HTML5',
        icon: <AiFillHtml5 />,
        color: '#e34f26',
    },
    {
        name: 'CSS3',
        icon: <IoLogoCss3 />,
        color: '#0645ad',
    },
    {
        name: 'JavaScript',
        icon: <IoLogoJavascript />,
        color: '#f7df1e',
    },
    {
        name: 'Bootstrap',
        icon: <BsFillBootstrapFill />,
        color: '#553a7d',
    },
    {
        name: 'JQuery',
        icon: < DiJqueryLogo />,
        color: '#0868ac',
    },
]

export default skills;