import React from 'react'
import './Skills.css'
import skills from './Skills-data'

const Skills = () => {
  return (
   <section className='skills' id='skills'>
      <div className="skills-container">
        <div className="skills-title">
          <h1>Technical Skills</h1>
          <div className="underline"></div>
        </div>
        <div className="skills-content">
          {skills.map((skill, index)=>{
            const {name, icon, color} = skill;
            return(
              <div className="skills-card" key={index}>
                <span style={{color:`${color}`}}>{icon}</span>
                <p>{name}</p>
              </div>
            )
            
          })}
          
        </div>
      </div>
   </section>
  )
}

export default Skills