import React from 'react'
import Pdf from '../../Cristian_Cadis_-_Frontend_Developer.pdf'
import './About.css'


const About = () => {
  return (
    <section className="about" id='about'>
      <div className="about-container">
        <div className="about-left">
          <Portret />
        </div>
        <div className="about-right">
          <div className="about-title">
              <h1>About Me</h1>
          </div>
          <div className="about-portret">
            <Portret />
          </div>
          <p>I describe myself as a passionate developer who loves coding, open source, and the web platform.
                    I like to create and contribute to open source projects. That helps me to learn new stuff, grow as a developer and support other open source projects.</p>
          <p>I enjoy taking complex problems and turning them into simple and beautiful interface designs. I also love the logic and structure of coding and always trying to write
                    elegant and efficient code whether it be HTML, CSS or React </p>
          <p>In my free time you can find me at the gym, reading a book or spending time with my friends.</p>     
          
          <div className="about-buttons">
            <a href="https://github.com/cristicadis" className='btn btn-primary ' target='_blank' rel="noopener noreferrer">Github</a>
            <a href={Pdf} className='btn btn-second' target = "_blank" rel="noopener noreferrer">Resume</a>
          </div>
          
        </div>
      </div>
    </section>
  )
}

const Portret = () => {
  return (
    <div className="about-image">
      <img src={require('./portret.jpeg')} alt="" />
    </div>
  )
}

export default About