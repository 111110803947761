const education = [
    {  
        title: 'Bachelor\u0027s degree',
        dates: '2011 - 2016',
        description: ['Fundamentals of Programming', 'Foundations of Computer Science','Operating Systems','Frontend Web Design','Computer Networks','Computer Graphics','Artificial Intelligence and Machine Learning','Data Science'],
        name: 'Control Engineering and Applied Computer Science Degree',
        school: 'THE TECHNICAL UNIVERSITY OF CLUJ-NAPOCA',
        url: 'https://ac.utcluj.ro/acasa.html'
    },
    {
        
        title: 'React Course',
        dates: '2022 - Present',
        description: ['Build, test, and launch React apps', 'Learn all about React Hooks and React Components','Become fluent in the toolchain supporting React, including NPM, Webpack, Babel, and ES6/ES2015 Javascript syntax','Realize the power of building reusable components','Learn the latest React libraries and tools'],
        name: 'React Tutorial and Projects Course (2023)',
        school: 'Udemy',
        url: 'https://www.udemy.com/course/react-tutorial-and-projects-course/',
        hours: 62.5,
    },
    {   
        title: 'Web Development',
        dates: '2021',
        description: ['Build 16 web development projects', 'Master backend development with Node','Learn the latest technologies, including Javascript, React, Node and even Web3 development.'],
        name: 'The Complete 2022 Web Development Bootcamp',
        school: 'Udemy',
        url: 'https://www.udemy.com/course/the-complete-web-development-bootcamp/',
        hours: 65.5,
    },
    { 
        title: 'HTML & CSS',
        dates: '2020',
        description: ['Modern, semantic and accessible HTML5', 'Important CSS concepts such as the box model, positioning schemes, inheritance, solving selector conflicts, etc.','How to plan, sketch, design, build, test, and optimize a professional website','How to make websites work on every possible mobile device (responsive design)','Modern CSS (previous CSS3), including flexbox and CSS Grid for layout'],
        name: 'Build Responsive Real-World Websites with HTML and CSS',
        school: 'udemy',
        url: 'https://www.udemy.com/course/design-and-develop-a-killer-website-with-html5-and-css3/',
        hours: 37.5,
    },


]

export default education;