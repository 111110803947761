import React from 'react'
import { Link as Link1 } from 'react-scroll'
import './Header.css'
import data from '../Skills/Skills-data'

const Header = () => {
  return (
    <section className="header" id='home'>
      <div className="header-container">
        <div className="header-left">
          <div className="header-title">Hello, my name is Cristian</div>
          <div className="header-text">
            <p> "I am a Junior Frontend Developer, graduated with a &nbsp; 
                <Link1  smooth={true} spy={true} to={'education'}  offset={-94} >Bachelor's degree in Computer Science</Link1>
                 , seeking a role where I can grow my skills in building responsive, beautiful and dynamic web apps and become a valued contributor to the team." </p>
          </div>
          <div className="header-signature"> — Cristian </div>
        </div>
        <div className="header-right">
          <div className="cubespinner">
            <div className="face1">{data[0].icon}</div>
            <div className="face2">{data[1].icon}</div>
            <div className="face3">{data[2].icon}</div>
            <div className="face4">{data[3].icon}</div>
            <div className="face5">{data[4].icon}</div>
            <div className="face6">{data[5].icon}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header