import React from 'react'
import {Header, About, Skills, Projects, Education, Contact, Footer, Navbar} from '../../components'


const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <About />
      <Skills />
      <Projects />
      <Education />
      <Contact />
      <Footer />
    </>
  )
}

export default Home