const projects = [
    {
        title: 'Gadget Shop',
        urlGit: 'https://github.com/cristicadis/gadget-je-shop-react',
        urlPrev: 'https://gadget-store-react.netlify.app',
        srcImg: require('./images/project_7.png'), 
    }, 
    {
        title: 'Quiz',
        urlGit: 'https://github.com/cristicadis/quiz-react-project',
        urlPrev: 'https://cris-react-quiz.netlify.app',
        srcImg: require('./images/project_1.webp'), 
    }, 
     {
        title: 'Dark Mode',
        urlGit: 'https://github.com/cristicadis/dark-mode-react',
        urlPrev: 'https://cris-react-dark-mode.netlify.app/',
        srcImg: require('./images/project_2.webp'),    
    },
     {
        title: 'Stock Photos',
        urlGit: 'https://github.com/cristicadis/stock-photos',
        urlPrev: 'https://cris-react-stock-photos.netlify.app',
        srcImg: require('./images/project_3.webp'), 
    },
    {
        title: 'Hacker News',
        urlGit: 'https://github.com/cristicadis/search-news-react',
        urlPrev: 'https://cris-react-hacker-news.netlify.app',
        srcImg: require('./images/project_4.webp'), 
    },
    {
        title: 'Drive Electric',
        urlGit: 'https://github.com/cristicadis/electric_car',
        urlPrev: 'https://cris-electric-car.netlify.app',
        srcImg: require('./images/project_5.png'), 
    },
     {
        title: 'Movie DB',
        urlGit: 'https://github.com/cristicadis/search-movies',
        urlPrev: 'https://cris-react-search-movie.netlify.app',
        srcImg: require('./images/project_6.webp'), 
    },
]

export default projects
