import React, { useRef, useEffect} from 'react'
import { FaBars, FaCloudDownloadAlt } from 'react-icons/fa'
import { Link as Link1 } from 'react-scroll'
import {links} from './Navbar-data'
import { useGlobalContext } from '../../context'
import Pdf from '../../Cristian_Cadis_-_Frontend_Developer.pdf'
import './Navbar.css'


const Navbar = () => {
  const {showLinks, setShowLinks} = useGlobalContext()
  const linksContainerRef = useRef(null)
  const linksRef = useRef(null)
  const navRef = useRef(null)
  


  //  set the hight of expanded menu
  useEffect(()=>{
    const linksHeight = linksRef.current.getBoundingClientRect().height
    if(showLinks){
      linksContainerRef.current.style.height = `${linksHeight}px`
    } else {
      linksContainerRef.current.style.height = `0px`
    }
  },[showLinks])

   // detect when click outside menu
   
      useEffect(() =>{
        function handleClickOutside(event){
          if(navRef.current && showLinks && !navRef.current.contains(event.target)){
            setShowLinks(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown',handleClickOutside);
         }
      }, [showLinks])
  


  return  (
    <nav ref={navRef}>
      <div className='nav-center'>
        <div className="nav-header">
             <a href='/' className='profile'>
                <span className='my-image'></span>
                <span className='my-name'>Cristian Cadis</span>
             </a>
             <button className="nav-toggle" onClick={()=>setShowLinks(!showLinks)}>
                <FaBars />
             </button>
        </div>
        {/* {`${showLinks ? 'links-container show-container' : 'links-container'}`} */}
        <div className= 'links-container' ref={linksContainerRef}>
          <ul className='links' ref={linksRef}>
            {links.map((link)=>{
              const {id, text} = link;
              return (
                <li key={id}>
                  <Link1 activeClass='active'  smooth={true} spy={true} to={text}  offset={-94} onClick={()=>setShowLinks(false)}>{text}</Link1>
                </li>)
            })}
          </ul>
        </div>
       
        <a href={Pdf} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Resume&nbsp;<FaCloudDownloadAlt className='icon-eye'/>    </a>
      </div>
    </nav>
  )
}

export default Navbar


 

 