import React from "react";
import {FaFacebookSquare, FaInstagramSquare, FaGithubSquare, FaLinkedin} from 'react-icons/fa';


export const social = [
    {
        id: 1,
        url: 'https://www.facebook.com/cristi.cadis',
        icon: <FaFacebookSquare />,
        name: 'Facebook',
    },
    {
        id: 2,
        url: 'https://www.instagram.com/cristicadis/?hl=en',
        icon: <FaInstagramSquare />,
        name: 'Instagram',
    },
    {
        id: 3,
        url: 'https://github.com/cristicadis',
        icon: <FaGithubSquare />,
        name: 'Github',
    },
    {
        id: 4,
        url: 'https://www.linkedin.com/in/cristian-c-39a58420a/',
        icon: <FaLinkedin />,
        name: 'Linkedin',
    },
]