import React from 'react'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import { social } from '../../social-icons-data'
import Zoom from '@mui/material/Zoom';
import "./Footer.css"; 

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    lineHeight:'1rem',
  },
}));



 const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-content">
        {social.map((item)=>{
          const {id, url, icon, name} = item
          return (
            <LightTooltip title={name} arrow  TransitionComponent={Zoom} >
              <IconButton >
                <a className='social-icons-footer' key={id} href={url} target = "_blank" rel="noopener noreferrer">{icon}</a>    
              </IconButton>
            </LightTooltip>      
          )
        })}
      </div>
      <div className="rights">
        &copy; {new Date().getFullYear()}{' '} 
        <span>Cristian Cadis</span>
        . All rights reserved.
      </div>
    </div>
  )
}

export default Footer